import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'

import Layout from '../components/layout'
import Hero from '../components/hero'

import Categories from '../components/categories'

class CategoryIndex extends React.Component {
  render() {
    const categories = get(this, 'props.data.allContentfulCategory.nodes')

    return (
      <Layout location={this.props.location}>
        <Hero title={'Kategóriák'} />
        <Categories categories={categories} />
      </Layout>
    )
  }
}

export default CategoryIndex

export const pageQuery = graphql`
  query CategoryQuery {
    allContentfulCategory(
      sort: { fields: [id], order: ASC }
      filter: { node_locale: { eq: "hu-HU" } }
    ) {
      nodes {
        id
        name
        thumbnail {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
  }
`
